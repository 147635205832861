<script>
export default {
    name: 'SortedMenu',
    data() {
        return {
            nsfwFilter: "Soft",
            options: ["Name", "Release", "Update"],
            activeSort: ""
        }
    },
    methods: {
        emitOption(option) {
            this.setActiveClass(option)
            this.activeSort = option
            this.$emit("emit-Option", {emitOption: option})
            },
        setActiveClass(o){
            let wasActive = document.getElementById(this.activeSort)
            let nowActive = document.getElementById(o)
            if(this.activeSort){
                wasActive.classList.toggle("isActive")
            }
            nowActive.classList.toggle("isActive")
        }
    }
}
</script>

<template>
    <menu id="sortedMenu">
        <h4>Sorted by :</h4>
        <button v-for="o in options" :key="o" :id="o" v-on:click="emitOption(o)" class="">{{o}}</button>
    </menu>
</template>

<style>
    #sortedMenu {
        text-align: center;
    }
    #sortedMenu h4 {
        margin-bottom: 0.5em;
    }
    #sortedMenu button {
        display: inline-block;
        padding: 0.5em;
        margin: 0 0.25em;
        border-radius: 1.5em;
        border: solid 1px cornflowerblue;
        color: cornflowerblue;
        text-shadow: 1px 1px 2px darkmagenta;
        background-color: rgba(72, 88, 116, 0.5);
    }
    #sortedMenu button:hover {
        border: solid 1px lightgoldenrodyellow;
        color: lightgoldenrodyellow;
        text-shadow: 1px 1px 2px rgba(72, 88, 116);
        background-color: cornflowerblue;
    }
    #sortedMenu button.isActive {
        border: solid 1px lightgoldenrodyellow;
        color: lightgoldenrodyellow;
        background-color: rgba(72, 88, 116, 0.75);
    }
</style>