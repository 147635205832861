<script>
import SortedMenu from "../components/SortedMenu";
import ElementCard from "../components/ElementCard";

import { sortedByOption } from "../modules/sortedMethods";

import jsonMods from "../data/dataMods.json";

export default {
  name: "Mods",
  components: { SortedMenu, ElementCard },
  props: {
    nsfwFilter: { type: String },
  },
  data() {
    return {
      dataMods: sortedByOption(jsonMods.data, "Update", this.nsfwFilter),
    };
  },
  methods: {
    sortedArray(payload) {
      sortedByOption(this.dataMods, payload.emitOption);
    },
  },
};
</script>

<template>
  <section>
    <h2>Mods</h2>
    <SortedMenu @emit-Option="sortedArray" />
    <ElementCard v-bind:dataArray="dataMods" />
  </section>
</template>
